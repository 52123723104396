.container {
  max-width: 47.75rem !important;
  /* max-height: 29.75rem !important; */
  max-height: 32.75rem !important;
}

.containerLarge {
  max-width: 47.75rem !important;
  /* max-height: 29.75rem !important; */
}

.containerSmall {
  max-width: 47.75rem !important;
  /* max-height: 29.75rem !important; */
  max-height: 32.75rem !important;
}

input {
  height: 2.25rem;
  border: 1px solid #e0e1dd;
  border-radius: 2px;
  padding: 0 0.5rem;
  width: 100%;
}
