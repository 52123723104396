.navicon {
  width: 24px;
  height: 24px;
}
.customUnderline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
  background-size: 0 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.2s ease-in-out;
}

.customUnderlineKuumapinkki {
  background-image: linear-gradient(transparent, transparent), linear-gradient(#ff007d, #ff007d);
}

.active, .customUnderline:hover {
  background-size: 100% 3px;
  background-position: 0 100%;
}
