.button-container{
  display: flex;
  margin: auto;
  width: 220px;
  margin-bottom: 20px;
}

.arrow-button{
  color: var(--dna-puhdasvalkoinen);
  width: 0px;
  height: 0px;
  border: 50px solid var(--dna-musta);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.arrow-button.right{
  border-left-color: var(--dna-musta);
  margin-left: 10px;
}

.arrow-button.left{
  border-right-color: var(--dna-musta);
  margin-right: 10px;
}

.kanban-container{
height: 100%;
}

.kanban-page-container{
  height: 90%;
  min-height: 700px;
  }

  .kanban-card-container{
    height: 100%;
    }

.kanban-card{
  width: 100%;
  height: 100%;
  background-color: var(--dna-puhdasvalkoinen);
  min-width: 300px;
  box-shadow: 2px 2px 10px var(--dna-backgroundgrey);
  position: relative;
}
.kanban-card .items-center{
  width: fit-content;
  min-width: 300px;
}

.kanban-card-header{
  width: 100%;
  height: 15%;
  background-color: var(--dna-kuumapinkki);
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.kanban-card-header header{
 color: var(--dna-puhdasvalkoinen);
 font-size: 2.875rem;

}

.progress-wrapper{
  display: initial;
  align-items: initial;
  position: absolute;
  top:20%;
  left:2.5%;
}

.progress-wrapper span{
  color: black !important;
  white-space: nowrap;
  margin-right: -100px;
}

.progress-wrapper-upper span{
  margin-top: -100px;
}

.progress-wrapper-lower span{
  margin-top: 100px;
}

.progress-wrapper-right span{
  margin-right: -20px;
}
.progress-wrapper-left span{
  margin-right: -180px;
}
.recharts-responsive-container{
  position: absolute;
  top:35%;
  left: 10%;
}
.custom-tooltip{
  background: var(--dna-puhdasvalkoinen);
  border: solid 1px var(--dna-musta);
  border-radius: 10px;
  padding: 20px;
  width: fit-content;
}