@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "DNA";
  font-weight: 900;
  src: local("DNA"), url(./fonts/DNAHeading-Black.woff) format("woff");
}

@font-face {
  font-family: "DNA";
  font-weight: 400;
  src: local("DNA"), url(./fonts/DNAText-Regular.woff) format("woff");
}

:root {
  /* Global CSS variables. */
  --dna-vedensininen: #007d91;
  --dna-meloninkeltainen: #ffa100;
  --dna-kivenharmaa: #4b5055;
  --dna-vaaleanharmaa: #e0e1dd;
  --dna-musta: #000000;
  --dna-puhdasvalkoinen: #ffffff;
  --dna-kuumapinkki: #ff007d;
  --dna-backgroundgray: #f7f7f7;
  --dna-textcolor: #121212;
}

*,
::before,
::after {
  font-family: "DNA";
  box-sizing: border-box;
  border-width: 0;
  /* border: 1px solid red; */
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  background-color: #f7f7f7;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--dna-backgroundgray);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.card {
  background-color: var(--dna-puhdasvalkoinen);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
