.react-kanban-column {
  max-width: 16.875rem;
  width: 100%;
  height: auto !important;
  background-color: white;
  min-width: 14rem;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
  margin-right: 4px;
  min-height: 90% !important;
  margin-left: 4px;
}
.react-kanban-board {
  overflow-y: hidden;
  display: flex;
  align-items: flex-start;

  width: 100%;
}

div[data-rbd-droppable-id="board-droppable"] {
  width: 100%;
  display: flex;
  margin: 0 4px;
}
div[data-rbd-droppable-id="board-droppable"],
div {
  width: 100%;
}
