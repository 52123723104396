.container {
  max-width: 47.75rem !important;
  max-height: 29.75rem !important;
}
input {
  height: 2.25rem;
  border: 1px solid #e0e1dd;
  border-radius: 2px;
  padding: 0 0.5rem;
  width: 100%;
}
textarea {
  width: 100%;
  border: 1px solid #e0e1dd;
  padding: 0.5rem;
  height: 100%;
  border-radius: 2px;
}
